import { registerApplication, start } from 'single-spa';
import {
  constructApplications,
  constructRoutes,
  constructLayoutEngine,
} from 'single-spa-layout';
import { RoutesConfig } from 'single-spa-layout/dist/types/isomorphic/constructRoutes';
import { setupGlobals } from './globals';
import { createLogger } from '@tapendium/pwa-lib';

const logger = createLogger('pwa');

/** Modules that are always loaded */
const STATIC_MODULES = [
  '@tapendium/pwa-lib',
  '@tapendium/authn-lib',
  '@tapendium/fset-lib',
];

/**
 * Import module and run bootstrap function if it exists
 */
const bootstrapModule = (moduleName: string) => {
  logger.debug('Importing %s', moduleName);
  System.import(moduleName)
    .then((mod) => {
      if (typeof mod?.bootstrap === 'function') {
        logger.debug('Found bootstrap function in %s', moduleName);
        try {
          mod.bootstrap();
        } catch (e) {
          logger.error('Bootstrap in %s threw an exception', moduleName);
          logger.error(e);
        }
      }
    })
    .catch((err) => {
      logger.error('Failed to import %s', moduleName);
      logger.error(err);
    });
};

// Import static modules
STATIC_MODULES.forEach(bootstrapModule);

/**
 * Load route configuration for single-spa layout engine
 */
const fetchRouteConfig = async () => {
  // Load route configuration directly from filesystem in local development
  if (process.env.NODE_ENV === 'development') {
    const routeConfigMod = await import('../../route-config.json');
    return routeConfigMod.default;
  }
  const routeConfigMod = await System.import('@tapendium-pwa/routes');

  return routeConfigMod.default;
};

// Define globally available configuration on window object
setupGlobals();

fetchRouteConfig()
  .then((config: RoutesConfig) => {
    if (process.env.NODE_ENV === 'development') {
      console.warn(
        "Route configuration is (re)generated as part of CI/CD process. Update 'route-config.json' for local development."
      );
    }

    const routes = constructRoutes(config);
    const applications = constructApplications({
      routes,
      loadApp({ name }) {
        return System.import(name);
      },
    });
    const layoutEngine = constructLayoutEngine({ routes, applications });

    for (const application of applications) {
      registerApplication(application);
    }

    layoutEngine.activate();

    start();
  })
  .catch((error) => logger.error(error));

export { logger };
