import { getGlobalConfig } from '@tapendium/pwa-lib';

export const appendCssLinkToHead = (entity?: {
  entityId: string;
  entityType: string;
}) => {
  const apiUrl = getGlobalConfig().apiUrl;
  const { entityId = '', entityType = '' } = entity ?? {};
  if (!entityId || !entityType) {
    return;
  }

  const cssLink = `${apiUrl}/get-css-by-entity?id=${entityId}&type=${entityType}`;

  const currentLinks = document.querySelectorAll(`[data-css-entity]`);

  if (currentLinks.length > 0) {
    currentLinks.forEach((link) => link.remove());
  }

  // TODO: [PWA-1732] get entity chain from new endpoint to loop through all entities
  const link = document.createElement('link');
  link['data-css-entity'] = entityId;
  link.rel = 'stylesheet';
  link.type = 'text/css';
  link.href = cssLink;
  document.head.appendChild(link);
};
