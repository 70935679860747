import { getGlobalConfig } from '@tapendium/pwa-lib';
import { logger } from './tapendium-pwa-root-config';

/** Get entity details for subdomain */
const getEntityDetails = async () => {
  try {
    const apiUrl = getGlobalConfig().apiUrl;
    const response = await fetch(`${apiUrl}/domain-management/subdomains`);
    if (response.ok) {
      return response.json();
    }
  } catch (e) {
    logger.error(e);
  }
};

export { getEntityDetails };
